
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        











































































































.choices__item {
  list-style-type: none;

  ::v-deep {
    [class*='action--'] {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: left;
    }
  }
}
